
import { defineComponent, PropType } from 'vue'
import { usersMixin } from '@/mixins/usersMixin'
import SurcoucheV2Tab from '@/components/SurcoucheV2/SurcoucheV2Tab.vue'
import SurcoucheV2Toolbar from '@/components/SurcoucheV2/SurcoucheV2Toolbar.vue'
import baseViewMixin from '@/mixins/baseViewMixin'
import { InstructionDsp, SelectedIo } from '../../types/instruction_type'
import { instructionsCaller } from '@/mixins/instructionsCaller'
import store from '../../store/index.js'
import { overlay } from '../../store/modules/overlay.js'
import * as SurcoucheV2Utils from '../components/SurcoucheV2/surcoucheV2utils'
import { getNewIoField } from '../../utils/instructionsUtils'
import {
  AutocompleteItem,
  EntitiesSchema,
  EntityType,
  QueryFilter,
  SurcoucheV2Filters,
  TabIndex,
  Tabs
} from '../../types/surcouche_v2_types'
import { Dictionary } from 'vue-router/types/router'
import { surcoucheV2Store } from '../../store/modules/surcoucheV2Store'
import {
  getUsersFilter,
  setFiltersFromQuery
} from '@/components/SurcoucheV2/surcoucheV2utils'

// load the store module dynamically only when needed
if (!store.state.overlay) store.registerModule('overlay', overlay)
if (!store.state.surcoucheV2Store) store.registerModule('surcoucheV2Store', surcoucheV2Store)
export default defineComponent({
  name: 'SurcoucheV2',
  components: {
    SurcoucheV2Toolbar,
    SurcoucheV2Tab,
    CompanySurcoucheV2: () => import('../components/SurcoucheV2/CompanySurcoucheV2.vue'),
    SeatSurcoucheV2: () => import('../components/SurcoucheV2/SeatSurcoucheV2.vue'),
    AdvertiserSurcoucheV2: () => import('../components/SurcoucheV2/AdvertiserSurcoucheV2.vue'),
    DV360CampaignSurcoucheV2: () => import('../components/SurcoucheV2/DV360CampaignSurcoucheV2.vue'),
    InsertionOrderView: () => import('../components/SurcoucheV2/InsertionOrderView.vue'),
    WaitBar: () => import('../components/Common/WaitBar.vue')
  },
  props: {
    dsp: {
      type: String as PropType<InstructionDsp>,
      required: true
    }
  },
  mixins: [instructionsCaller, usersMixin, baseViewMixin],
  data: function () {
    return {
      search: '' as string,
      items: null,
      tabIndex: null,
      users: null,
      instructions: null,
      currentTab: '',
      messageCallProcess: '',
      resultSync: {} as AutocompleteItem,
      autocompleteFromUrl: {} as AutocompleteItem,
      height: 'calc(100vh - 255px)', /* (59px footer + 68px tabs header + 88px filter bar + 40px navbar) => 255px */
      tabs: [
        { type: 'company', title: 'Company', component: 'CompanySurcoucheV2', disabled: true },
        { type: 'siege', title: 'Seat', component: 'SeatSurcoucheV2', disabled: true },
        { type: 'advertiser', title: 'Advertiser', component: 'AdvertiserSurcoucheV2', disabled: true },
        { type: 'campaign', title: 'DV 360 Campaign', component: 'DV360CampaignSurcoucheV2', disabled: true },
        { type: 'insertionOrder', title: 'Insertion Order', component: 'InsertionOrderView', disabled: false }
      ] as Tabs[],
      selectedIosProps: {} as PropType<SelectedIo>
    }
  },
  created: function () {
  },
  mounted: async function () {
    this.onMounted()
    if (!this.$store.getters.getUsers.length) {
      await this.$store.dispatch('getUsersData')
    }
    await this.$store.dispatch('getV2ApiCountries')
    await this.$store.dispatch('getV2ApiAlertCategories')
    this.$store.commit('setSurcoucheV2SearchParamsFilters', { filter: 'dsp', value: this.dsp })
    this.users = getUsersFilter(this.$store.getters.getUsers)
    await this.getQueryParams()
  },
  methods: {
    async getQueryParams () {
      let autocompleteFilter: AutocompleteItem = null
      let entities: EntitiesSchema = null
      if (this.$route.query.search !== undefined) {
        this.search = this.$route.query.search as string
        const type = this.$route.params.tab
        switch (type) {
          case 'insertionOrder':
            ({ autocompleteFilter, entities } = await this.$store.dispatch('getAutocompleteInsertionOrder', { dsp: this.dsp, search: this.search }))
            break
          case 'advertiser':
            /*  ({ autocompleteFilter, entities } = await setupAutocompleteAdvertiser(this.search, this.dsp, type))
            this.$store.commit('setSurcoucheV2AdvertisersSearchResults', entities) */
            break
          case 'campaign':
            /*  ({ autocompleteFilter, entities } = await setupAutocompleteDV360Campaign(this.search, this.dsp, type))
            this.$store.commit('setSurcoucheV2DV360CampaignsSearchResults', entities) */
            break
          case 'seat':
            /*  ({ autocompleteFilter, entities } = await setupAutocompleteSeat(this.search, this.dsp, type))
            this.$store.commit('setSurcoucheV2SeatsSearchResults', entities) */
            break
          case 'company':
            /*  ({ autocompleteFilter, entities } = await setupAutocompleteCompany(this.search, this.dsp, type))
            this.$store.commit('setSurcoucheV2CompaniesSearchResults', entities) */
            break
          default:
            console.warn('Sorry, this type does not exist.')
        }
        this.resultSync = autocompleteFilter
      }

      this.filters = setFiltersFromQuery(this.$route.query, autocompleteFilter)
    },
    updateTabIndex (dsp: InstructionDsp) {
      this.tabIndex = this.tabs
        .filter(tab => tab.type !== 'campaign' || ['dbm', 'youtube'].includes(this.dsp))
        .reduce((acc, item, index) => {
          acc[item.type as EntityType] = index
          return acc
        }, {} as TabIndex)
    },
    askSnackBar (message: string, status = 'success') {
      this.$emit('ask-snackbar', message, status)
    },
    // A rendre generique pour les autres entite egalement ne fonctionne que pour io pour le moment
    searchRedirect (filters: QueryFilter) {
      const newIoFieldKeyName = getNewIoField(this.dsp)
      this.search = ''
      if (filters?.autocomplete && Object.keys(filters?.autocomplete).length > 0) {
        if (filters?.autocomplete?.type === 'insertionOrder') {
          this.search = filters?.autocomplete.external_id
        } else {
          this.search = ''
        }
      } else {
        this.search = ''
      }
      const tabSearch = filters.autocomplete?.type ?? this.$route.params.tab

      const { autocomplete, ...query } = filters
      query.search = this.search
      //
      const path = this.$router.resolve({
        name: this.$route.name,
        params: { dsp: this.dsp, tab: tabSearch },
        query: query as Dictionary<string | string[]>
      })

      if (path.href !== this.$route.fullPath) {
        this.$router.push(path.location)
      }
    },
    toogleExpendFilter () {
      setTimeout(() => {
        this.height = `calc(100vh - ${document.getElementById('headerBlock').offsetHeight}px - 185px)`
      }, 400)
    }
  },
  computed: {
    filterTabs () : Tabs[] {
      return this.tabs.filter((tab) => {
        if (tab.type === 'campaign' && !['dbm', 'youtube'].includes(this.dsp)) {
          return false
        }
        return true
      })
    },
    tab: {
      get () : number {
        return Number(SurcoucheV2Utils.getTabForEntity(this.$route.params.tab as EntityType, this.tabIndex))
      },
      set (value: number) {
        this.$router.replace({
          name: 'surcoucheV2',
          params: { dsp: this.$route.params.dsp, tab: SurcoucheV2Utils.getEntityForTab(value, this.tabIndex) }
        })
      }
    },
    filters: {
      get () : SurcoucheV2Filters {
        return this.$store.getters.getSurcoucheV2Filters
      },
      set (value: SurcoucheV2Filters) {
        this.$store.commit('setSurcoucheV2Filters', value)
      }
    },
    keyTab (): string {
      return `tab_${this.dsp}`
    },
    keyTabItem (): string {
      return `tab_item_${this.dsp}`
    }
  },
  watch: {
    '$route.params.dsp': {
      deep: true,
      immediate: true,
      handler: function (dsp) {
        this.$store.commit('setOptionsInstructions', { page: 1, itemsPerPage: 20 })
        this.$store.commit('setStreamingProcessInProgress', false)
        this.updateTabIndex(dsp)
      }
    },
    'callProcessIsRunning': function (callProcessIsRunning) {
      if (callProcessIsRunning) {
        this.messageCallProcess = this.whichProcessRun === 'create' || this.whichProcessRun === 'createMulti' ? 'Create new element process...' : 'Buckets edit is processing...'
      }
    },
    filters: {
      deep: true,
      immediate: false,
      handler: function (queryFilters: QueryFilter) {
        this.searchRedirect(queryFilters)
      }
    }
  }
})
